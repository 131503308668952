// images
import Logo from "../src/assets/img/header/logo.svg";

import YogaImg from "../src/assets/img/workouts/clase-yoga.jpg";

import CommunityImg1 from "../src/assets/img/community/img1.png";
import CommunityImg2 from "../src/assets/img/community/img2.png";
import CommunityImg3 from "../src/assets/img/community/img3.png";
import CommunityImg4 from "../src/assets/img/community/img4.png";
import JoinImg from "../src/assets/img/banner/bannerJoin.jpg";
// icons
import UsersIcn from "../src/assets/img/about/icons/users-icn.svg";
import CalendarIcn from "../src/assets/img/workouts/icons/calendar.svg";
import PriceIcn from "../src/assets/img/pricing/icons/price.svg";
import CommunityIcn from "../src/assets/img/community/icons/community-icn.svg";
import QuestionMarkIcn from "../src/assets/img/faq/icons/question-mark.svg";
import ciclismoInterior from "../src/assets/img/workouts/ciclismo-interior.jpg";
import funcional from "../src/assets/img/workouts/clase-funcional.jpg";
import consultasPrivadas from "../src/assets/img/workouts/consultas-privadas.jpg";

import logoStarke from "../src/assets/img/starke/centro-de-entrenamiento.png";

export const header = {
  logo: Logo,
  //btnLoginText: 'Log in',
  // btnSignupText: 'Sign Up',
};

export const nav = [
  { name: "Nosotros", href: "nosotros" },
  { name: "Disciplinas", href: "disciplinas" },
  //{ name: "Precios", href: "precios" },
  { name: "Comunidad", href: "comunidad" },
  { name: "Preguntas", href: "preguntas" },
  { name: "Contacto", href: "contacto" },
];

export const banner = {
  titlePart1: "Logra tu mejor version",
  titlePart2: "– Starke Fitness.",
  subtitle: "Ofrecemos un espacio amigable, seguro y motivador.",
  textBtn: "Nuestro objetivo",
  img: "",
};

export const about = {
  icon: UsersIcn,
  title: "Nuestro objetivo",
  subtitle1:
    "Nos distinguimos por nuestra atmósfera motivadora, que ayuda a nuestros miembros a alcanzar sus objetivos de acondicionamiento físico individuales.",
  subtitle2:
    "La fuerza de nuestra sincera identidad se utiliza para inspirar a cada persona que pone un pie en nuestros gimnasios a mejorar.",
  link: "Disciplinas",
};

export const workouts = {
  icon: CalendarIcn,
  title: "Disciplinas",
  programs: [
    {
      image: consultasPrivadas,
      name: "Personalizados",
      message:
      "¡Hola! He estado interesado/a en las clases Personalizadas que ofrecen. ¿Podrías darme más información al respecto? ",
      number:'1123709305'

    },
    {
      image: funcional,
      name: "Funcional",
      message:
      "¡Hola! He estado interesado/a en las clases de Funcional que ofrecen. ¿Podrías darme más información al respecto? ",
      number:'1123709305'
    },



    {
      image: YogaImg,
      name: "Yoga",
      message:
      "¡Hola! He estado interesado/a en las clases de Yoga que ofrecen. ¿Podrías darme más información al respecto? ",
    },
    {
      image: ciclismoInterior,
      name: "Ciclismo de interior",
      message:
      "¡Hola! He estado interesado/a en las clases de Ciclismo de interior que ofrecen. ¿Podrías darme más información al respecto? ",
      number:'1139096842'

    },
  ],
};

export const pricing = {
  icon: PriceIcn,
  title: "Valores de planes",
  plans: [
    {
      name: "Clase",
      price: "$1500",
      list: [{ name: "Clase de una hora" }, { name: "Personal Trainer" }],
      delay: 600,
      message:'¡Hola! He estado interesado/a en la Clase que ofrecen. ¿Podrías darme más información al respecto?'
    },
    {
      name: "Standard",
      price: "$6.000",
      list: [
        { name: "8 Clases mensuales" },
        { name: "2 Veces por semana" },
        { name: "Personal Trainer" },
      ],
      delay: 800,
      message:'¡Hola! He estado interesado/a en el plan Standard que ofrecen. ¿Podrías darme más información al respecto?'
    },
    {
      name: "Medium",
      price: "$8.000",
      list: [
        { name: " 12 Clases mensuales" },
        { name: "3 Veces por semana" },
        { name: "Personal Trainer" },
        { name: "Consultas por WhatsApp" },
      ],
      delay: 1000,
      message:'¡Hola! He estado interesado/aen el plan Medium  que ofrecen. ¿Podrías darme más información al respecto?'
    },
    {
      name: "Premium",
      price: "$10.000",
      list: [
        { name: " 16 Clases mensuales" },
        { name: "4 Veces por semana" },
        { name: "Personal Trainer" },
        { name: "Consultas por WhatsApp" },
        { name: "Guía practica de alimentos" },
      ],
      delay: 1200,
      message:'¡Hola! He estado interesado/a en el plan Premium que ofrecen. ¿Podrías darme más información al respecto?'
    },
  ],
};

export const community = {
  icon: CommunityIcn,
  title: "Comunidad",
  testimonials: [
    {
      image: CommunityImg1,
      name: "Matias",
      message: "Profesor de funcional",
    },
    {
      image: CommunityImg3,
      name: "Bruno",
      message: "Profesor de spinning",
    },
    {
      image: CommunityImg2,
      name: "Profesor 3",
      message: "Profesor de yoga",
    },
    {
      image: CommunityImg4,
      name: "Profesor 4",
      message: "Otra disciplina",
    },
  ],
};

export const faq = {
  icon: QuestionMarkIcn,
  title: "Preguntas",
  accordions: [
    {
      question: "¿Que es el entrenamiento funcional?",
      answer:
        "El entrenamiento funcional se trata de ejercicios que imitan movimientos reales, ayudando a fortalecer el cuerpo para tareas cotidianas y deportes, mientras se evitan lesiones",
    },
    {
      question: "¿Qué es el entrenamiento personalizado?",
      answer:
        "El entrenamiento personalizado con programas adaptados individualmente para fortalecer, mejorar la resistencia, aumentar la flexibilidad y alcanzar metas específicas de bienestar físico y mental, según las necesidades y objetivos de cada persona.",
    },
    {
      question: "¿Qué es el yoga?",
      answer:
        "El yoga es una práctica milenaria que combina posturas físicas, respiración y meditación para promover el equilibrio entre cuerpo y mente, mejorando la flexibilidad, fuerza y bienestar general.",
    },
    {
      question: "¿Que es ciclismo de interior?",
      answer:
        " El ciclismo de interior, también conocido como ciclismo en interiores o spinning, es una actividad de ejercicio cardiovascular que se realiza en bicicletas estáticas especialmente diseñadas.",
    },
  ],
};

export const join = {
  image: JoinImg,
  title: "Wanna join & have fun?",
  subtitle:
    "Estamos aquí para que encuentres tu mejor version. Nada mas y nada menos.",
  btnText: "Empezar ahora",
};

export const footer = {
  logo: logoStarke,
  copyrightText: "Todos los derechos reservados. StarkeFitness 2023.",
};
